div:has(> .mytable) {
    height: 100%;
    overflow-y: scroll;
}

.firstHead{
    border-top-left-radius: 5px;
}
.lastHead{
    border-top-right-radius: 5px;
}

th{
    font-size: 14px;
}

td{
    font-size: 14px;
}

::-webkit-scrollbar {
    width: 5px;
  }
::-webkit-scrollbar-thumb {
    background: #d3d3d3; 
  }

  @media only screen and (max-width: 1400px) {
    th{
        font-size: 11px;
    }
    td{
        font-size: 10px;
    }
}