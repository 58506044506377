div:has(> .mytable) {
    height: 500px;
    overflow-y: scroll;
}

.firstHead{
    border-top-left-radius: 5px;
}
.lastHead{
    border-top-right-radius: 5px;
}

::-webkit-scrollbar {
    width: 5px;
  }
::-webkit-scrollbar-thumb {
    background: #d3d3d3; 
  }
