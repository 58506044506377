.container-fluid{
  background-color: #f5f5f5;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Bold.ttf") format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-ExtraBold.ttf") format('truetype');
  font-weight: 900;
}

*{
  font-family: "Montserrat";
}

p{
  font-size: 16px;
}

.fs-20{
  font-size: 20px;
}
.react-datepicker-wrapper{
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}