.otherTh{
    width: 200px !important;
    font-size: 14px;
}
.dOfGoodTh{
    width: 800px !important;
}

.mytBody td{
    font-size: 12px;
}